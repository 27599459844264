<template>
    <div class="event-one" v-if="eventType === 'sign'">
        <ActiveCard v-for="(item, index) in eventList" :key="index" :data="item" :roomId="parseInt(roomId)"></ActiveCard>
<!--        <div class="event-body">-->
<!--            <div class="event-title video">-->
<!--                <span style="margin-left: 30px">视频直播</span>-->
<!--                <span style="color: #FFFFFF; float: right; font-size: 14px; margin-right: 10px">更多<i class="el-icon-arrow-right"></i></span>-->
<!--            </div>-->
<!--            <div class="event-content">-->
<!--                <div>-->
<!--                    <div style="font-size: 18px; font-weight: 600">线上报名参加活动</div>-->
<!--                    <div style="color: #2970FF">2022/6/18  PM18:30</div>-->
<!--                    <div><span>活动地点： </span> <span style="font-weight: 600">线上</span></div>-->
<!--                    <div><span>热度： </span> <span style="font-weight: 600">889人将参加</span></div>-->
<!--                </div>-->
<!--                <div>-->
<!--                    <img src="" class="image-ico">-->
<!--                    <div class="hand op image-btn">点击报名</div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

<!--        <div class="event-body">-->
<!--            <div class="event-title punch">-->
<!--                <span style="margin-left: 30px">打卡事件</span>-->
<!--                <span style="color: #FFFFFF; float: right; font-size: 14px; margin-right: 10px">更多<i class="el-icon-arrow-right"></i></span>-->
<!--            </div>-->
<!--            <div class="event-content">-->
<!--                <div>-->
<!--                    <div style="font-size: 18px; font-weight: 600">线上报名参加活动</div>-->
<!--                    <div style="color: #2970FF">2022/6/18  PM18:30</div>-->
<!--                    <div><span>打卡地点： </span> <span style="font-weight: 600">线上</span></div>-->
<!--                </div>-->
<!--                <div>-->
<!--                    <img src="" class="image-ico">-->
<!--                    <div class="hand op image-btn">点击报名</div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

<!--        <div class="event-body">-->
<!--            <div class="event-title" style="background-color: #FF9800">-->
<!--                <i class="el-icon-message-solid" style="margin-right: 6px; line-height: 40px"></i>-->
<!--                日程提醒-->
<!--            </div>-->
<!--            <div class="event-info">-->
<!--                <div style="margin-bottom: 10px; color: #F3425F">2022-02-21  AM10：30</div>-->
<!--                <div>-->
<!--                    备注：xxxxxxxxxxxxxxxxxxx-->
<!--                    xxxxxxxxxxxxxxxxxxxxxxxx-->
<!--                    xxxxxxxxxxxxxxxxxxxxxxxx-->
<!--                    xxxxxxxxxxxxxxxxxxxxxxxx-->
<!--                    xxxxxxxxxxxx-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

<!--        <div class="event-body">-->
<!--            <div class="event-title" style="background-color: #FD764C">-->
<!--                <i class="el-icon-message-solid" style="margin-right: 6px; line-height: 40px"></i>-->
<!--                多人预约日程-->
<!--            </div>-->
<!--            <div class="event-info">-->
<!--                <div style="margin-bottom: 10px; color: #F3425F">2022-02-21  AM10：30</div>-->
<!--                <div style="margin-bottom: 10px">-->
<!--                    备注：xxxxxxxxxxxxxxxxxxx-->
<!--                    xxxxxxxxxxxxxxxxxxxxxxxx-->
<!--                    xxxxxxxxxxxxxxxxxxxxxxxx-->
<!--                    xxxxxxxxxxxxxxxxxxxxxxxx-->
<!--                    xxxxxxxxxxxx-->
<!--                </div>-->
<!--                <div>-->
<!--                    <div style="color: #999999; margin-bottom: 10px">好友：</div>-->
<!--                    <div style="display: flex; flex: content">-->
<!--                        <img src="" style="width: 38px; height: 38px; border-radius: 50%; margin-right: 8px" v-for="item in 6" :key="item">-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

<!--        <div class="event-body">-->
<!--            <div class="event-title money">-->
<!--                <span style="margin-left: 30px">收款事件</span>-->
<!--                <span style="color: #FFFFFF; float: right; font-size: 14px; margin-right: 10px">更多<i class="el-icon-arrow-right"></i></span>-->
<!--            </div>-->
<!--            <div class="event-content">-->
<!--                <div>-->
<!--                    <div style="font-size: 18px; font-weight: 600">线上报名参加活动</div>-->
<!--                    <div style="color: #2970FF">2022/6/18  PM18:30</div>-->
<!--                    <div><span>支付金额： </span> <span style="font-weight: 600">888元</span></div>-->
<!--                    <div><span>付费： </span> <span style="font-weight: 600">889人已购买</span></div>-->
<!--                </div>-->
<!--                <div>-->
<!--                    <img src="" class="image-ico">-->
<!--                    <div class="hand op image-btn">点击报名</div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

<!--        <div class="event-body">-->
<!--            <div class="event-title pay">-->
<!--                <span style="margin-left: 30px">支付事件</span>-->
<!--                <span style="color: #FFFFFF; float: right; font-size: 14px; margin-right: 10px">更多<i class="el-icon-arrow-right"></i></span>-->
<!--            </div>-->
<!--            <div class="event-content">-->
<!--                <div>-->
<!--                    <div style="font-size: 18px; font-weight: 600">线上审批支付</div>-->
<!--                    <div style="color: #2970FF">2022/6/18  PM18:30</div>-->
<!--                    <div><span>支付金额： </span> <span style="font-weight: 600">999元</span></div>-->
<!--                </div>-->
<!--                <div>-->
<!--                    <div class="hand op image-btn">点击查看</div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="hand op" style="display: flex; justify-content: end; color: #2970FF; font-weight: 600; margin-bottom: 20px"><i class="el-icon-arrow-right" style="margin-right: 2px; line-height: 22px"></i>查看全部事件</div>-->
    </div>
</template>

<script>
    import ActiveCard from "./event/ActiveCard";
    export default {
        name: "EventOne",
        components: {ActiveCard},
        data() {
            return {
                eventList: []
            }
        },
        props: {
            roomId: {
                type: String,
                default() {
                    return ''
                }
            },
            eventType: {
                type: String,
                default() {
                    return ''
                }
            }
        },

        computed: {
            user() {
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            },
        },

        mounted() {
            var that = this;
            this.getEnrollList();
            this.$EventBus.$on("reshEvent", (data) => {
                this.$nextTick(() => {
                    that.getEnrollList();
                });
            });
        },

        methods: {
            // 获取报名事件列表
            getEnrollList() {
                this.newApi.getEnrollEventList({roomId: this.roomId, status: 2}).then(res =>{
                    this.eventList = res.data
                })
            }
        }
    }
</script>

<style scoped>
    .event-one {
        width: 100%;
    }

    .event-one .event-body {
        margin-bottom: 10px;
    }

    .event-one .event-body .event-title {
        height: 40px;
        text-align: center;
        line-height: 40px;
        color: #FFFFFF;
        font-size: 18px;
        font-weight: 600;
    }

    .event-one .event-body .active {
        background-image: url("../imgs/title1.png");
    }

    .event-one .event-body .video {
        background-image: url("../imgs/title2.png");
    }

    .event-one .event-body .make {
        background-image: url("../imgs/title6.png");
    }

    .event-one .event-body .punch {
        background-image: url("../imgs/title4.png");
    }

    .event-one .event-body .money {
        background-image: url("../imgs/title5.png");
    }

    .event-one .event-body .pay {
        background-image: url("../imgs/title3.png");
    }

    .event-one .event-body .event-content {
        min-height: 120px;
        display: flex;
        justify-content: space-between;
        padding: 20px;
        line-height: 30px;
        background-color: #FFFFFF;
        border-radius: 0 0 5px 5px;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
    }

    .event-one .event-body .event-info {
        min-height: 120px;
        padding: 20px;
        line-height: 20px;
        background-color: #FFFFFF;
        border-radius: 0 0 5px 5px;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
    }

    .event-one .event-body .event-content .image-ico {
        width: 84px;
        height: 66px;
        margin-top: 10px;
    }

    .event-one .event-body .event-content .image-btn {
        width: 88px;
        height: 28px;
        text-align: center;
        line-height: 28px;
        font-weight: 600;
        border-radius: 5px;
        background-color: #E4E6EB;
        color: #2970FF;
    }
</style>
