<template>
    <div class="event">
      <RoomNav name="RoomNav"></RoomNav>
        <div style="width: 1500px;margin:0 auto;overflow: hidden;margin-top:110px;">
            <div style="width:894px;height: auto;float: left;margin: 0 0 0 294px;">
                <SignView v-if="eventType === 'sign'" :activeId="id"></SignView>
                <VideoView v-if="eventType === 'video'"></VideoView>
            </div>

            <div class="page-right" style="width:302px;float: right;">
                <div style="width:100%;height:auto;">
                    <!-- room信息 -->
                    <div style="background:#FFF;padding:15px;margin-bottom: 10px;box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);">
                        <div style="color: #444444;font-size: 14px;">
                            <div style="font-weight: 600; font-size: 16px; margin-bottom: 8px">事件所属:</div>
                            <div class="contentTypes">
                                <i class="el-icon-s-home" style="margin-right: 5px;"></i>
                                <span>{{room.name}}</span>
                            </div>
                        </div>
                        <div style="color: #444444;font-size: 14px;">
                            <span style="font-weight: 600; font-size: 16px">事件创建人:</span>
                            <div style="margin: 0 0 20px 0">
                                <User :data="userInfo"></User>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 事件 -->
                <EventOne :roomId="this.$route.query.roomId" :eventType="eventType"></EventOne>
            </div>
        </div>
    </div>
</template>

<script>
    import RoomNav from "./components/RoomNav";
    import User from "@/components/common/User.vue";
    import SignView from "@/components/common/signUp/signView.vue";
    import VideoView from "./components/event/videoView";
    import EventOne from "./components/EventOne";

    export default {
        name: "event",
        components: {EventOne, VideoView, SignView, User,RoomNav},
        data() {
            return {
                room: {
                    user: {}
                },

                userInfo: {},

                eventType: this.$route.query.type,

                roomId: this.$route.query.roomId,
                id: parseInt(this.$route.query.id)
            }
        },

        watch: {
            '$route': {
                handler(newData) {
                    this.roomId = newData.query.roomId;
                    this.id = newData.query.id;
                    this.getRoomInfo();
                    this.getEventInfo()
                }
            }
        },

        mounted() {
            this.getRoomInfo();
            this.getEventInfo()
        },

        methods: {

            // 获取room信息
            getRoomInfo() {
                this.newApi.getRoomInfo({
                    roomId: this.roomId
                }).then(ret => {
                    this.room = ret.data;
                })
            },

            // 获取创建者信息
            getEventInfo() {
                this.newApi.getEnrollInfo({id: this.id}).then(res =>{
                    if (res.isSuccess === 1) {
                        this.newApi.getUserInfo({uuid: res.data.uuid}).then(res => {
                            this.userInfo = res.data
                        })
                    }
                })
            },
        }
    }
</script>

<style scoped>
    .event {
        overflow:hidden;
        background-color: #f0f0f0;
        min-width: 1500px;
        min-height: 100vh;
    }

    .event .page-right .contentTypes {
        color: #031F88;
        margin: 0 0 20px 10px;
        height: 16px;
        font-weight: 600;
    }
</style>
