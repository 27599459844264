<template>
    <div class="video-view">
        <div class="right-top">
            <div class="hand op" style="font-size: 18px; font-weight: 600;" @click="goBack">
                <i class="el-icon-arrow-left" style="font-weight: 600"></i>返回
            </div>
            <div style="width: 720px;font-size: 20px; font-weight: 600;">
                <div style="text-align: center">
                    <span>现场会议</span>
                </div>
            </div>
        </div>
        <!-- 背景图 -->
        <div :style="{'background-image': `url(${signForm.cover})`}" class="back-image" v-if="videoType === '1'">
            <div class="back-image" style="background: rgba(0,0,0,0.39)">
                <div style="width: 80px; height: 80px; border-radius: 50%; border: 4px #FFFFFF solid; text-align: center; color: #FFFFFF; font-size: 20px; font-weight: 600; position: absolute; top: 47%; left: 47%">
                    <div style="width: 70px; height: 70px; border-radius: 50%; border: 1px #FFFFFF solid; text-align: center; margin: 4px 0 0 4px">
                        <div style="margin-top: 30%">未开始</div>
                    </div>

                </div>
            </div>
        </div>

        <div class="back-image" v-if="videoType === '2'" style="background-color: #6d737b; color: #FFFFFF">
            视频直播区域
        </div>

        <div style="width: 834px; min-height: 600px; padding: 20px 30px; background-color: #FFFFFF;">
            <div class="back-foot">
                <div class="back-title">
                    关于召开庆祝第xx个教师节暨表彰大会的通知
                </div>

                <div style="display: flex; justify-content: space-between">
                    <div style="display: flex; justify-content: space-between;background-color: #E4E6EB;padding: 6px 10px; border-radius: 5px; width: 480px; font-weight: 600; font-size: 16px; margin-right: 20px">
                        <div>
                            <i class="el-icon-alarm-clock" style="color: #1a1a1a; margin-right: 6px"></i>
                            <span>直播未开始</span>
                        </div>
                        <div style="font-size: 14px">
                            <span style="color: #999999">
                               剩余开始时间：
                            </span>
                            <span style="color: #F3425F">
                               2小时30分钟
                            </span>
                        </div>
                    </div>
                    <div style="display: flex;">
                        <div class="back-btn">
                            <i class="el-icon-circle-plus" style="margin-right: 4px"></i>
                            <span>报名</span>
                        </div>
                        <div class="back-btn">
                            <i class="el-icon-circle-plus" style="margin-right: 4px"></i>
                            <span>邀请</span>
                        </div>
                        <div class="send-btn">
                            <img src="../../imgs/send.png">
                        </div>
                    </div>
                </div>
            </div>
            <div class="content">
                <span class="content-title">视频类型：</span>
                <span>现场会议</span>
            </div>
            <div class="content">
                <span class="content-title">课程日期：</span>
                <span>2022年7月17日，星期三10:00 - 12:00（北京时间）</span>
            </div>
            <div class="content">
                <span class="content-title">课程报名：</span>
                <span>共有19人参加会议</span>
            </div>
            <div class="content">
                <span class="content-title">会议详情描述：</span>
                <div></div>
            </div>

            <div style="border: 1px #999999 dashed; margin: 20px 0"></div>

            <div class="content">
                <span class="content-title">附件：</span>
                <div class="title">
                    <div class="file-box hand op" @click="downloadFiles(item.url, item.name)" v-for="(item, index) in signForm.files" :key="index">
                        <img src="https://oss.baigongbao.com/img/file.png" style="width: 24px; height: 20px; margin-right: 12px; vertical-align: middle">
                        <span style="font-size: 18px; vertical-align: middle">{{item.name}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "videoView",
        data() {
            return {
                signForm: {
                    type: '0',
                    name: '',
                    time: '',
                    place: '',
                    description: '',
                    files: [{
                        name: '会议记录模板',
                        url: '1',
                        type: '1'
                    }],
                    image: '',
                    tags: [],
                    cover:""
                },

                videoType: '2',
            }
        },

        props: {
            // room: {
            //     type: Object,
            //     default() {
            //         return {
            //             user: {},
            //             cover: ''
            //         }
            //     }
            // }
        },

        methods: {
            // 返回上一页
            goBack() {
                this.$router.go(-1)
            },

            // 下载附件
            downloadFiles(url, name) {
                this.utils.download(url, name)
            },
        }
    }
</script>

<style scoped>
    .video-view .right-top {
        width: 854px;
        background-color: #fff;
        display: flex;
        padding: 8px 20px;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
    }

    .video-view .back-image {
        width:100%;
        height:440px;
        background-repeat:no-repeat;
        background-size:100%;
        background-position:center center;
    }

    .video-view .back-foot {
        width: 834px;
        height: 100px;
    }

    .video-view .back-title {
        font-size: 26px;
        font-weight: 600;
        margin-bottom: 20px;
    }

    .video-view .back-btn {
        width: 108px;
        height: 24px;
        margin-right: 10px;
        border-radius: 5px;
        background-color: #E4E6EB;
        padding: 4px 0;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
    }

    .video-view .send-btn {
        width: 48px;
        height: 20px;
        margin-right: 10px;
        border-radius: 5px;
        background-color: #E4E6EB;
        padding: 6px 0;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
    }

    .video-view .content {
        font-size: 16px;
    }

    .video-view .content-title {
        color: #999999;
        line-height: 36px;
    }

    .video-view .title{
        overflow: hidden;
        overflow-y: scroll;
        max-height: 300px;
        line-height: 26px;
        font-size: 15px;
    }

    .video-view .title::-webkit-scrollbar {
        display: none;
    }

    .video-view .file-box {
        height: 32px;
        padding: 8px 10px;
        margin-bottom: 4px;
        background-color: #F1F1F1;
    }
</style>
