var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"video-view"},[_c('div',{staticClass:"right-top"},[_c('div',{staticClass:"hand op",staticStyle:{"font-size":"18px","font-weight":"600"},on:{"click":_vm.goBack}},[_c('i',{staticClass:"el-icon-arrow-left",staticStyle:{"font-weight":"600"}}),_vm._v("返回 ")]),_vm._m(0)]),(_vm.videoType === '1')?_c('div',{staticClass:"back-image",style:({'background-image': `url(${_vm.signForm.cover})`})},[_vm._m(1)]):_vm._e(),(_vm.videoType === '2')?_c('div',{staticClass:"back-image",staticStyle:{"background-color":"#6d737b","color":"#FFFFFF"}},[_vm._v(" 视频直播区域 ")]):_vm._e(),_c('div',{staticStyle:{"width":"834px","min-height":"600px","padding":"20px 30px","background-color":"#FFFFFF"}},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticStyle:{"border":"1px #999999 dashed","margin":"20px 0"}}),_c('div',{staticClass:"content"},[_c('span',{staticClass:"content-title"},[_vm._v("附件：")]),_c('div',{staticClass:"title"},_vm._l((_vm.signForm.files),function(item,index){return _c('div',{key:index,staticClass:"file-box hand op",on:{"click":function($event){return _vm.downloadFiles(item.url, item.name)}}},[_c('img',{staticStyle:{"width":"24px","height":"20px","margin-right":"12px","vertical-align":"middle"},attrs:{"src":"https://oss.baigongbao.com/img/file.png"}}),_c('span',{staticStyle:{"font-size":"18px","vertical-align":"middle"}},[_vm._v(_vm._s(item.name))])])}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"720px","font-size":"20px","font-weight":"600"}},[_c('div',{staticStyle:{"text-align":"center"}},[_c('span',[_vm._v("现场会议")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"back-image",staticStyle:{"background":"rgba(0,0,0,0.39)"}},[_c('div',{staticStyle:{"width":"80px","height":"80px","border-radius":"50%","border":"4px #FFFFFF solid","text-align":"center","color":"#FFFFFF","font-size":"20px","font-weight":"600","position":"absolute","top":"47%","left":"47%"}},[_c('div',{staticStyle:{"width":"70px","height":"70px","border-radius":"50%","border":"1px #FFFFFF solid","text-align":"center","margin":"4px 0 0 4px"}},[_c('div',{staticStyle:{"margin-top":"30%"}},[_vm._v("未开始")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"back-foot"},[_c('div',{staticClass:"back-title"},[_vm._v(" 关于召开庆祝第xx个教师节暨表彰大会的通知 ")]),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","background-color":"#E4E6EB","padding":"6px 10px","border-radius":"5px","width":"480px","font-weight":"600","font-size":"16px","margin-right":"20px"}},[_c('div',[_c('i',{staticClass:"el-icon-alarm-clock",staticStyle:{"color":"#1a1a1a","margin-right":"6px"}}),_c('span',[_vm._v("直播未开始")])]),_c('div',{staticStyle:{"font-size":"14px"}},[_c('span',{staticStyle:{"color":"#999999"}},[_vm._v(" 剩余开始时间： ")]),_c('span',{staticStyle:{"color":"#F3425F"}},[_vm._v(" 2小时30分钟 ")])])]),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"back-btn"},[_c('i',{staticClass:"el-icon-circle-plus",staticStyle:{"margin-right":"4px"}}),_c('span',[_vm._v("报名")])]),_c('div',{staticClass:"back-btn"},[_c('i',{staticClass:"el-icon-circle-plus",staticStyle:{"margin-right":"4px"}}),_c('span',[_vm._v("邀请")])]),_c('div',{staticClass:"send-btn"},[_c('img',{attrs:{"src":require("../../imgs/send.png")}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('span',{staticClass:"content-title"},[_vm._v("视频类型：")]),_c('span',[_vm._v("现场会议")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('span',{staticClass:"content-title"},[_vm._v("课程日期：")]),_c('span',[_vm._v("2022年7月17日，星期三10:00 - 12:00（北京时间）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('span',{staticClass:"content-title"},[_vm._v("课程报名：")]),_c('span',[_vm._v("共有19人参加会议")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('span',{staticClass:"content-title"},[_vm._v("会议详情描述：")]),_c('div')])
}]

export { render, staticRenderFns }